<template>
  <app-module-view>
    <template slot="buttons">
      <router-link
        tag="button"
        class="btn btn btn-secondary"
        :to="{ name: 'printPublication_preview' }"
      >
        <i class="far fa-newspaper"></i> {{ $t("printPublication.buttons.display_current") }}
      </router-link>
      <router-link
        tag="button"
        class="btn btn btn-success"
        :to="{ name: 'printPublication_new' }"
      >
        <i class="fas fa-plus"></i> {{ $t("printPublication.buttons.add") }}
      </router-link>
    </template>
    <template slot="body">
      <app-print-publication-filter></app-print-publication-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="printPublications"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import PrintPublicationFilter from './PrintPublicationFilter'
import DataTable from '../shared/DataTable'
import PrintTitleService from '../../services/print/PrintTitleService'

export default {
  name: 'PrintPublicationList',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('printPublication.list.id'),
          printTitle: this.$t('printPublication.list.title_title'),
          title: this.$t('printPublication.list.title'),
          publicationDate: this.$t('printPublication.list.publicationDate')
        },
        actions: {
          detail: 'printPublication_detail',
          edit: 'printPublication_edit'
        },
        render: {
          printTitle: function (data) {
            if (data) {
              return this.printTitleById(data)
            }
          }.bind(this)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    printPublications () {
      return this.$store.getters['printPublication/list']
    },
    totalCount () {
      return this.$store.getters['printPublication/totalCount']
    },
    page () {
      return this.$store.getters['printPublication/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appPrintPublicationFilter: PrintPublicationFilter,
    appDataTable: DataTable
  },
  methods: {
    getPrintPublications () {
      this.$store.dispatch('printPublication/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('printPublication/setPage', page)
      this.getPrintPublications()
    },
    printTitleById (id) {
      return PrintTitleService.getPrintTitleTitle(id)
    }
  },
  mounted () {
    this.getPrintPublications()
  }
}
</script>

<style lang="scss" scoped>
.loader-td {
  position: relative;
  height: 80px;
  background-color: white;
  overflow: hidden;
}
</style>
